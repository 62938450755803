<template>
	<div class="p-4">
		<div class="flex flex-col w-full max-w-md py-4 mx-auto text-center align-center">
			<h1 class="mb-4 text-5xl font-extrabold">
				Let's be <span class="italic">
					blunt
				</span>.
			</h1>
			<p class="mb-4 text-lg">
				The URL that you were looking for has changed or no longer exists.
			</p>
			<router-link
				:to="{ name: 'home' }"
				class="inline-block"
			>
				Back to {{ APP_META.DOMAIN_NAME }}
			</router-link>
		</div>

		<div
			v-if="hasBlogArticles"
			class="flex flex-wrap"
		>
			<h2 class="mt-4">
				Recent Articles
			</h2>
			<div class="flex flex-wrap">
				<BlogCard
					v-for="(article, index) in blogArticlesCapped"
					:key="index"
					:article="article"
				/>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import { APP_META } from '@/constants/index.js'

export default {
	components: {
		BlogCard: () => import('@/components/blog/Card.vue')
	},
	data() {
		return {
			APP_META
		}
	},
	computed: {
		...mapGetters('city', [ 'blogArticles' ]),
		blogArticlesCapped() {
			return this.hasBlogArticles && this.blogArticles.slice(0, 4)
		},
		hasBlogArticles() {
			return !!this.blogArticles?.length
		}
	}
}
</script>
